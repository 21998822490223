<template>
    <div class="container">
        <index-content v-if="userInfo" :userInfo="userInfo" :theme="theme" :socials="socials"
            :id="userInfo.theme_style_id" :isPreview="true">
        </index-content>
    </div>
</template>
  
<script>
import IndexContent from './index_content.vue';
import { get, post } from '../config/http';
import api from '../config/api';

export default {
    components: { IndexContent },
    props: {

    },
    data() {
        return {
            userInfo:null,
            theme: JSON.parse(this.$route.query.theme),
            socials:null
        }
    },
    mounted() {
        this.loadData()
    },
    methods: {
        loadData() {
            var _this = this;
            Promise.all([get(api.userSocials), get(api.socials,{all:true})]).then((list) => {
                _this.userInfo = list[0];
                _this.socials = list[1].data
            });
        },
    }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
h1 {
    font-size: 0.2rem;
}
</style>